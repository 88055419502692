<!--
* @Author: DY
* @Date: 2020-05-27 14:52:13
* @Description: ''
-->
<template>
    <div class="fomeStyle_dy addVehicle" v-loading="loading">
        <div class="content_box">
            <table>
                <tbody>
                    <tr>
                        <th><i>*</i>车牌号码</th>
                        <td colspan="3">
                            <el-input v-model="addData.car_no" placeholder="请输入车牌号码"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th><i>*</i>司机姓名</th>
                        <td>
                            <el-input v-model="addData.driver_name" placeholder="请输入司机姓名"></el-input>
                        </td>
                        <th><i>*</i>司机电话</th>
                        <td>
                            <el-input v-model="addData.driver_phone" placeholder="请输入司机电话"></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th><i>*</i>供应商</th>
                        <td @click="chooseSupplier">
                            <el-input
                                v-model="addData.supplier_name"
                                placeholder="请选择供应商"
                                readonly
                            ></el-input>
                        </td>
                        <th><i>*</i>运输单位</th>
                        <td @click="chooseTransport">
                            <el-input
                                v-model="addData.transport_name"
                                placeholder="请选择运输单位"
                                readonly
                            ></el-input>
                        </td>
                    </tr>
                    <tr>
                        <th>备注</th>
                        <td colspan="3">
                            <el-input
                                type="textarea"
                                :autosize="{ minRows: 2 }"
                                placeholder="请填写备注"
                                v-model="addData.remarks"
                            >
                            </el-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <footer>
            <el-button @click="cancel">
                取消
            </el-button>
            <el-button type="primary" @click="confirm" v-if="!isReadOnly">
                确认
            </el-button>
        </footer>
    </div>
</template>

<script>
import store from '@/store';

export default {
    // 添加车辆（原材料运输车辆）
    name: 'add-vehicle',
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            isReadOnly: false,
            addData: {
                id: '',
                // 车牌号
                car_no: '',
                // 司机姓名
                driver_name: '',
                // 司机手机号
                driver_phone: '',
                // 供应商Id
                supplier_id: '',
                // 供应商name
                supplier_name: '',
                // 运输单位Id
                transport_id: '',
                // 运输单位name
                transport_name: '',
                // 备注
                remarks: '',
            },
            loading: false,
        };
    },
    watch: {},
    computed: {},
    created() {
        // 有id 编辑
        if (this.extr.id) {
            this.loading = true;
            // 编辑初始化页面
            this.initData();
        }
        // 只读
        if (this.extr.isReadOnly) {
            this.isReadOnly = true;
        }
    },
    mounted() {},
    methods: {
        // 取消
        cancel() {
            this.$parent.hide();
        },
        // 编辑初始化页面
        initData() {
            this.$axios
                .get(`/interfaceApi/Procurement/truck/gettruck/${this.extr.id}`)
                .then(res => {
                    if (res) {
                        this.addData = res;
                    }
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 选择供应商
        chooseSupplier() {
            if (this.isReadOnly) {return;}
            this.$toast({
                title: true,
                type: 'eject',
                width: '12rem',
                height: '7rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ycl_yscl_xzgys',
                    },
                    sureCallBack: this.chooseSupplierCallBack,
                },
            });
        },
        // 选择供应商回调
        chooseSupplierCallBack(data) {
            this.addData.supplier_name = data.name;
            this.addData.supplier_id = data.id;

            this.addData.transport_name = '';
            this.addData.transport_id = '';
        },
        // 选择运输单位
        chooseTransport() {
            if (this.isReadOnly) {return;}
            this.$toast({
                title: true,
                type: 'eject',
                width: '12rem',
                height: '7rem',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ycl_yscl_xzysdw',
                    },
                    sureCallBack: this.chooseTransportCallBack,
                },
            });
        },
        // 选择运输单位回调
        chooseTransportCallBack(data) {
            this.addData.transport_name = data.name;
            this.addData.transport_id = data.id;

            this.addData.supplier_name = '';
            this.addData.supplier_id = '';
        },
        // 确认添加
        confirm() {
            if (this.addData.car_no === '') {
                this.$message({
                    message: '请填写车牌号！',
                    type: 'warning',
                });
            } else if (this.addData.driver_name === '') {
                this.$message({
                    message: '请填写司机姓名！',
                    type: 'warning',
                });
            } else if (this.addData.driver_phone === '') {
                this.$message({
                    message: '请填写司机手机号！',
                    type: 'warning',
                });
            } else {
                this.$axios
                    .post('/interfaceApi/Procurement/truck/savetruck', this.addData)
                    .then(res => {
                        if (res) {
                            this.$message({
                                message: '添加成功！',
                                type: 'success',
                            });
                            this.$parent.hide();
                            store.state.currentOpenList.forEach(item => {
                                item.tableObj.updateTable();
                            });
                        }
                    })
                    .catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
    },
};
</script>

<style lang="stylus">
.addVehicle
    height 100%
    padding 0.15rem
    position relative
    .content_box
        height calc(100% - 0.6rem)
        overflow-y auto
        // table
        //     width 100%
        //     border-collapse collapse
        //     th,td
        //         line-height 0.36rem
        //         font-size 0.16rem
        //         padding: 0.05rem 0
        //     th
        //         color: #4E4F4F
        //         font-weight normal
        //         padding-left 0.2rem
        //         i
        //             color red
        //     td
        //         .el-input__inner
        //             height 0.36rem
        //             line-height 0.36rem
    #annexData
        padding 0
    footer
        width 100%
        position absolute
        bottom 0.2rem
        left 0
        text-align center
        .el-button
            width 1.5rem
            padding 0
            font-size 0.16rem
            line-height 0.4rem
            margin 0 0.2rem
            height 0.4rem
</style>
